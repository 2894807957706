/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProjectCardSmall_project$ref = any;
export type ProjectsQueryVariables = {||};
export type ProjectsQueryResponse = {|
  +projects: ?$ReadOnlyArray<?{|
    +$fragmentRefs: ProjectCardSmall_project$ref
  |}>
|};
export type ProjectsQuery = {|
  variables: ProjectsQueryVariables,
  response: ProjectsQueryResponse,
|};
*/


/*
query ProjectsQuery {
  projects {
    ...ProjectCardSmall_project
  }
}

fragment ProjectCardSmall_project on Project {
  title
  description
  slug
  image
}
*/

const node/*: ConcreteRequest*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "projects",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectCardSmall_project"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProjectsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "projects",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "845a2e2ea6cead872a5dfd4f6b1fac32",
    "id": null,
    "metadata": {},
    "name": "ProjectsQuery",
    "operationKind": "query",
    "text": "query ProjectsQuery {\n  projects {\n    ...ProjectCardSmall_project\n  }\n}\n\nfragment ProjectCardSmall_project on Project {\n  title\n  description\n  slug\n  image\n}\n"
  }
};
// prettier-ignore
(node/*: any*/).hash = 'fc9b56a97f553285a554751332623dba';

module.exports = node;
