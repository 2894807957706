import React from 'react';
// graphql and relay imports
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
// ui imports
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    maxWidth: 290,
    margin:"7px",
  },
  media: {
    height: 140,
  },
});

function ProjectCardSmall(props) {
  const classes = useStyles();
  let slug_url = "#/projects/" + props.project.slug;

  return (
    <Card className={classes.root} data-testid="project-card">
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={props.project.image}
          title={props.project.title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.project.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.project.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" href={slug_url}>
          See Details
        </Button>
      </CardActions>
    </Card>
  );
}

export default createFragmentContainer(
  ProjectCardSmall,
  { project: graphql`
    fragment ProjectCardSmall_project on Project {
      title
      description
      slug
      image
    }
  `, }
)