import React, { useEffect } from 'react';
// graphql and relay imports
import graphql from 'babel-plugin-relay/macro';
import {QueryRenderer} from 'react-relay';
// ui imports
import Grid from '@material-ui/core/Grid';
import ProjectCardSmall from './ProjectCardSmall';
import { CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
// google analytics import
import ReactGA from 'react-ga';

function Projects(props) {
    useEffect(() => {
        // Update the document title using the browser API
        document.title = "Projects - DavidAdkins.com";
        if (process.env.NODE_ENV !== 'test') {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    });

    // todo: load the media cards dynamically from graphql, need image field too
    return (
        <Grid container justify="flex-start" alignItems="flex-start">
            <QueryRenderer
                environment={props.environment}
                query={graphql`
                query ProjectsQuery{
                    projects {
                        ...ProjectCardSmall_project
                    }
                }
                `}
                render={({error, props}) => {
                    if (error) { 
                        return <Alert severity="error" data-testid="errorButton">Error Loading Projects.</Alert>; 
                    }
                    if (!props) { 
                        return <CircularProgress data-testid="spinner" />; 
                    }
                    return props.projects.map(( node ) => <ProjectCardSmall key={node.__id} project={node} />)
                    }
                }
            />
        </Grid>
    );
}

export default Projects;