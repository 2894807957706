import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Alert } from '@material-ui/lab';

function NotFound(props) {
    useEffect(() => {
        // Update the document title using the browser API
        document.title = "Not Found - David Adkins";
        if (process.env.NODE_ENV !== 'test') {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    });
    return (
        <Alert severity="error" data-testid="notFound">
            Error 404 - Page Not Found
        </Alert>
    );
}
export default NotFound;