import './App.css';
import React from 'react';

import { Switch, Route } from "react-router-dom";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import Profile from './components/Profile';
import Home from './components/Home';
import Projects from './components/Projects';
import Project from './components/Project';
import Resume from './components/Resume';
import NotFound from './components/NotFound';

import environment from './Environment';

import photo from './images/profile.jpg';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-3409363-10', { testMode: (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') });

const name = "David Adkins";

const useStyles = makeStyles(theme => ({
  color: {
    backgroundColor: '#e0692d'
  }
}));

function App() {

  const classes = useStyles();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <Container maxWidth="lg">
          <Grid container spacing={3}>

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Profile name={name} photo={photo} />
            </Grid>

            <Grid item lg={9} md={9} sm={6} xs={12} className={classes.color}  data-testid="content-page">
              <Switch>
                <Route exact path="/resume">
                  <Resume />
                </Route>
                <Route exact path="/projects">
                  <Projects environment={environment} />
                </Route>
                <Route path="/projects/:slug">
                  <Project environment={environment} />
                </Route>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="*" component={NotFound} status={404} />
              </Switch>
            </Grid>

          </Grid>
        </Container>
    </ThemeProvider>
  );
}

export default App;