import React, { useEffect } from 'react';
// graphql and relay imports
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
// react router import to use history
import { useHistory } from 'react-router-dom';
// ui imports
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
// to process markdown
import ReactMarkdown from 'react-markdown';
// google analytics import
import ReactGA from 'react-ga';

const useStyles = makeStyles({
    root: {
      maxWidth: "100%",
      margin:"7px",
    },
    media: {
      height: 340,
    },
  });

function ProjectCardLarge(props) {
    const classes = useStyles();
    let history = useHistory();

    useEffect(() => {
        // Update the document title using the browser API
        document.title = `${props.project.title} - DavidAdkins.com`;
        if (process.env.NODE_ENV !== 'test') {
          ReactGA.pageview(window.location.pathname + window.location.search);
      }
    });

    return (
        <Card className={classes.root} data-testid="project-card">
          <CardActions>
              <Button onClick={() => history.go(-1)}>Back</Button>
          </CardActions>
          <CardActionArea>
              <CardMedia
              className={classes.media}
              image={props.project.image}
              title={props.project.title}
              />
              <CardContent>
              <Typography gutterBottom variant="h2" component="h2">
                  {props.project.title}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                  <ReactMarkdown source={props.project.content} />
              </Typography>
              </CardContent>
          </CardActionArea>
          <CardActions>
              <Button onClick={() => history.go(-1)}>Back</Button>
          </CardActions>
        </Card>
    );
}

export default createFragmentContainer(
    ProjectCardLarge,
    { project: graphql`
      fragment ProjectCardLarge_project on Project {
        title
        content
        image
      }
    `, }
  )