/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProjectCardLarge_project$ref = any;
export type ProjectQueryVariables = {|
  slug: string
|};
export type ProjectQueryResponse = {|
  +project: ?{|
    +$fragmentRefs: ProjectCardLarge_project$ref
  |}
|};
export type ProjectQuery = {|
  variables: ProjectQueryVariables,
  response: ProjectQueryResponse,
|};
*/


/*
query ProjectQuery(
  $slug: String!
) {
  project(slug: $slug) {
    ...ProjectCardLarge_project
  }
}

fragment ProjectCardLarge_project on Project {
  title
  content
  image
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectCardLarge_project"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b9577ff8ed061509a972270b6adee542",
    "id": null,
    "metadata": {},
    "name": "ProjectQuery",
    "operationKind": "query",
    "text": "query ProjectQuery(\n  $slug: String!\n) {\n  project(slug: $slug) {\n    ...ProjectCardLarge_project\n  }\n}\n\nfragment ProjectCardLarge_project on Project {\n  title\n  content\n  image\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd2a254145f14d03047f8f77f51216508';

module.exports = node;
