import React, { useState, useEffect } from 'react';
// ui imports
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import 'react-pdf/dist/umd/Page/AnnotationLayer.css';
// other imports
import resume from '../images/resume.pdf';
// ga imports
import ReactGA from 'react-ga';

function Resume(props) {
    useEffect(() => {
        // Update the document title using the browser API
        document.title = "Resume - DavidAdkins.com";
        if (process.env.NODE_ENV !== 'test') {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    });

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
      setPageNumber(1);
    }
  
    function changePage(offset) {
      setPageNumber(prevPageNumber => prevPageNumber + offset);
    }
  
    function previousPage() {
      changePage(-1);
    }
  
    function nextPage() {
      changePage(1);
    }

    return (
        <Grid container direction="column" alignItems="center">
            <Grid item>
                <Typography variant='h6'>
                    <Button disabled={pageNumber <= 1} onClick={previousPage}>
                        <ArrowBackIosIcon />
                    </Button>
                    {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                    <Button disabled={pageNumber >= numPages} onClick={nextPage} >
                        <ArrowForwardIosIcon />
                    </Button>
                </Typography>
            </Grid>
            <Document 
                file={resume} 
                onLoadSuccess={onDocumentLoadSuccess} 
                loading="Loading..."
                error = "Error..."
            >
                <Page pageNumber={pageNumber} />
            </Document>
        </Grid>
    );
}

export default Resume;