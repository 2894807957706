import React, { useEffect } from 'react';
// ui imports
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// ga imports
import ReactGA from 'react-ga';

const useStyles = makeStyles(theme => ({
    paper: {
      margin:'40px auto',
      padding:'25px',
    }
  }));

function Home(props) {
    useEffect(() => {
        // Update the document title using the browser API
        document.title = "Home - David Adkins";
        if (process.env.NODE_ENV !== 'test') {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    });
    const classes = useStyles();

    return ( 
        <Paper elevation={3} square={true} className={classes.paper}>
            I really don't have a lot to say here.  I wanted to brush up on React and Node
            wanted to learn about writing Jest tests and deploying sites through Travis to GH Pages.
            Now I have my own site, it's ugly, but it's mine. I may update, I may not, time will tell.
            <Typography component={'span'} variant={'body2'}>
                Here's a quick intro on my interests:

                <ul>
                    <li>Front End Dev (React, JS, Node)</li>
                    <li>Back End Dev (Golang, Python, PHP)</li>
                    <li>Machine Learning (Reinforcement Learning, CNN, GANs)</li>
                    <li>Responsible AI (Interpretability, Robustness, Explainability)</li>
                </ul>

            </Typography>
            <Typography component={'span'} variant={'body2'}>
            I want some cards here for some of my personal projects.
            </Typography>
        </Paper>
    );
}

export default Home;