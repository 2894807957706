import React from 'react';
// ui imports
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import DescriptionIcon from '@material-ui/icons/Description';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import GitHubIcon from '@material-ui/icons/GitHub';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import WorkIcon from '@material-ui/icons/Work';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function ProfileList(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>

      <List component="nav">
      <ListItemLink href="/">
          <Typography variant='h6'>{props.name}</Typography>
        </ListItemLink>
        <ListItemLink href="#/projects" data-testid="project-link">
          <ListItemIcon>
            <WorkIcon />
          </ListItemIcon>
          <ListItemText primary="Projects" />
        </ListItemLink>
        <ListItemLink href="#/resume" data-testid="resume-link">
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary="Resume" />
        </ListItemLink>
      </List>
      <Divider />
      <List component="nav" aria-label="secondary mailbox folders">
        <ListItemLink href="https://linkedin.com/in/davidjadkins">
          <ListItemIcon>
            <LinkedInIcon />
          </ListItemIcon>
          <ListItemText primary="davidjadkins" />
        </ListItemLink>
        <ListItemLink href="https://facebook.com/DavidJAdkins">
          <ListItemIcon>
            <FacebookIcon />
          </ListItemIcon>
          <ListItemText primary="DavidJAdkins" />
        </ListItemLink>
        <ListItemLink href="https://github.com/dadkins20">
          <ListItemIcon>
            <GitHubIcon />
          </ListItemIcon>
          <ListItemText primary="dadkins20" />
        </ListItemLink>
        <ListItemLink href="https://twitter.com/davidadkins">
          <ListItemIcon>
            <TwitterIcon />
          </ListItemIcon>
          <ListItemText primary="davidadkins" />
        </ListItemLink>
        <ListItemLink href="https://instagram.com/adventuresofdavid">
          <ListItemIcon>
            <InstagramIcon />
          </ListItemIcon>
          <ListItemText primary="adventuresofdavid" />
        </ListItemLink>
      </List>
    </div>
  );
}
