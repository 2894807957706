import {
    Environment,
    Network,
    RecordSource,
    Store,
  } from 'relay-runtime';

const apihost = (process.env.NODE_ENV === 'development' ? '' : 'https://davidadkinscom.uk.r.appspot.com');
  
function fetchQuery(operation,variables,) {
    return fetch(`${apihost}/graphql`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query: operation.text,
            variables,
        }),
    }).then(response => {
        return response.json();
    });
}
  
const environment = new Environment({
    network: Network.create(fetchQuery),
    store: new Store(new RecordSource()),
});

export default environment;