import React from 'react';
// react router import to get slug from URL
import { useParams } from 'react-router-dom';
// graphql and relay imports
import {QueryRenderer} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
// ui imports
import ProjectCardLarge from './ProjectCardLarge';
import { CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

function Project(props) {
    let { slug } = useParams();

    return (
            <QueryRenderer
                environment={props.environment}
                query={graphql`
                query ProjectQuery($slug: String!){
                    project (slug: $slug) {
                        ...ProjectCardLarge_project
                    }
                }
                `}
                variables={{slug}}
                render={({error, props}) => {
                    if (error) { 
                        return <Alert severity="error" data-testid="errorButton">Error Loading Project.</Alert>; 
                    }
                    if (!props) { 
                        return <CircularProgress data-testid="spinner" />; 
                    }
                    return <ProjectCardLarge project={props.project} />;
                    }
                }
            />
    );
}
export default Project;