/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectCardSmall_project$ref: FragmentReference;
declare export opaque type ProjectCardSmall_project$fragmentType: ProjectCardSmall_project$ref;
export type ProjectCardSmall_project = {|
  +title: ?string,
  +description: ?string,
  +slug: ?string,
  +image: ?string,
  +$refType: ProjectCardSmall_project$ref,
|};
export type ProjectCardSmall_project$data = ProjectCardSmall_project;
export type ProjectCardSmall_project$key = {
  +$data?: ProjectCardSmall_project$data,
  +$fragmentRefs: ProjectCardSmall_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectCardSmall_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '9674ada12edd319b5f253ec0fe946be9';

module.exports = node;
