import React from 'react';
// ui imports
import ProfilePhoto from './ProfilePhoto';
import ProfileList from './ProfileList';
import Grid from '@material-ui/core/Grid';

function Profile(props) {
    return (
        <Grid container direction="column" justify="center" alignItems="center" >
            <ProfilePhoto photo={props.photo} 
                alt={props.name}
                size="large"
            />
            <ProfileList name={props.name} />
        </Grid>
    );
}
export default Profile;